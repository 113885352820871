exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-jspwellness-js": () => import("./../../../src/pages/projects/jspwellness.js" /* webpackChunkName: "component---src-pages-projects-jspwellness-js" */),
  "component---src-pages-projects-mavi-js": () => import("./../../../src/pages/projects/mavi.js" /* webpackChunkName: "component---src-pages-projects-mavi-js" */),
  "component---src-pages-projects-telegram-roster-bot-js": () => import("./../../../src/pages/projects/telegram-roster-bot.js" /* webpackChunkName: "component---src-pages-projects-telegram-roster-bot-js" */),
  "component---src-pages-projects-with-love-js": () => import("./../../../src/pages/projects/with-love.js" /* webpackChunkName: "component---src-pages-projects-with-love-js" */),
  "component---src-pages-scratchpad-index-js": () => import("./../../../src/pages/scratchpad/index.js" /* webpackChunkName: "component---src-pages-scratchpad-index-js" */),
  "component---src-pages-sjbuild-index-js": () => import("./../../../src/pages/sjbuild/index.js" /* webpackChunkName: "component---src-pages-sjbuild-index-js" */)
}

